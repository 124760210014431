import { getInstance } from '../auth'

const createClient = async () => {
  const authService = await getInstance()
  const token = await authService.getTokenSilently()
  const axios = (await import('axios')).default

  return axios.create({
    baseURL: process.env.VUE_APP_BOTTIMMO_SERVICE,
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob'
  })
}

export default createClient
