import PropertyType from '@/lib/enums/valuation/PropertyType'
import PropertySubType from '@/lib/enums/valuation/PropertySubType'

export const propertyTypes = {
  [PropertyType.HOUSE]: 'valuation.property-type.house',
  [PropertyType.APARTMENT]: 'valuation.property-type.apartment',
  [PropertyType.MULTI_FAMILY_HOUSE]: 'valuation.property-type.multi-family-house'
}

export const propertySubTypes = {
  [PropertySubType.APARTMENT_NORMAL]: 'valuation.property-sub-type.apartment-normal',
  [PropertySubType.APARTMENT_ATTIC]: 'valuation.property-sub-type.apartment-attic',
  [PropertySubType.APARTMENT_MAISONETTE]: 'valuation.property-sub-type.apartment-maisonette',
  [PropertySubType.APARTMENT_PENTHOUSE]: 'valuation.property-sub-type.apartment-penthouse',
  [PropertySubType.APARTMENT_STUDIO]: 'valuation.property-sub-type.apartment-studio',
  [PropertySubType.APARTMENT_TERRACED]: 'valuation.property-sub-type.apartment-terraced',
  [PropertySubType.HOUSE_DETACHED]: 'valuation.property-sub-type.house-detached',
  [PropertySubType.HOUSE_SEMI_DETACHED]: 'valuation.property-sub-type.house-semi-detached',
  [PropertySubType.HOUSE_ROW_CORNER]: 'valuation.property-sub-type.house-row-corner',
  [PropertySubType.HOUSE_ROW_MIDDLE]: 'valuation.property-sub-type.house-row-middle',
  [PropertySubType.HOUSE_FARM]: 'valuation.property-sub-type.house-farm'
}
